<template>
  <div class="driver">
    <!-- 主体内容 -->
    <div class="driver_cont">
      <div
        class="pattern cont"
        v-class="
          'pattern cont animate__animated animate__fadeInup animate__block'
        "
      >
        <div class="second">
          <span class="size36">董事长寄语</span>
          <p>Message</p>
          <div class="img">
            <img class="simg" src="../../../static/images/home/people.jpg" />
          </div>
          <div class="second_part">
            <div class="part">
              <span>刘勇</span>
              <p>和行科技 董事长</p>
            </div>
          </div>
          <div class="message">
            <p>
              百余年历史的汽车产业，正面临颠覆性变革。在中国，汽车电动化、智能化、网联化、共享化正以一种相互促进、递进、融合的关系，不断拓展。汽车四化为代表的未来新方式也将推动服务变革，移动出行给我们带来更多的想象空间，各大车企都在搭建自己的移动出行平台。
            </p>
            <p>
              “和行约车”是江汽集团顺应行业发展趋势，积极探索推进的“互联网+出行”新业务。经过数年的积淀磨炼，我们依托江汽集团丰富的产品品类，将全球领先的互联网、车联网以及汽车科技，应用于共享出行领域，致力于为用户提供安全、快捷、舒适、绿色的一站式出行解决方案。
            </p>
            <p>
              展望未来，和行约车将持续丰富和完善产品布局，着力探索搭建特色鲜明的行业赛道，提升研发实力，把握新时代特征，力争为更多用户带来更多元、更高品质的出行服务体验。我们将始终静守初心，砥砺奋斗，赓续前行。
            </p>
          </div>
        </div>
      </div>
    </div>
    <foot :animate="false"></foot>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.size36 {
  font-size: 0.52rem;
}
p {
  margin-left: 1px;
  font-size: 0.26rem;
}
.animate__block {
  visibility: visible !important;
}
.animate__animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-duration: var(--animate-duration);
  animation-duration: var(--animate-duration);
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  animation-delay: 0.2s;
}
.animate__fadeInup {
  -webkit-animation-name: FadeInup;
  animation-name: FadeInup;
}
.animate__animated {
  --animate-duration: 0.5s;
}
.animate__animated.animate__fadeIn {
  --animate-duration: 0.5s;
}
.driver {
  position: relative;
}
.driver_cont {
  margin-bottom: 0.5rem;
}
.cont {
  color: #2d2d39;
  font-size: 0.36rem;
  visibility: hidden;
}
.cont .second {
  display: flex;
  flex-direction: column;
  margin-top: 1.2rem;
}
.pattern .second {
  padding: 0 0.48rem;
}
.pattern .second span {
  font-weight: bold;
  margin: 0.25rem 0 0.07rem;
}
.part span,
.part p {
  text-align: center;
  margin-top: 10px;
  line-height: 0.4rem;
}
.message {
  margin-bottom: 1.3rem;
}
.message p {
  font-size: 0.3rem;
  line-height: 30px;
  text-align: justify;
  margin-bottom: 0.2rem;
}
.pattern .second .second_part .part {
  display: flex;
  flex-direction: column;
  margin-top: 0.4rem;
}
.pattern .second .second_part .part span {
  font-size: 0.4rem;
  margin: 0.23rem 0.24rem 0.13rem;
}
.pattern .second .second_part .part p {
  font-size: 0.3rem;
  color: #848494;
  margin: 0 0.24rem 0.33rem;
}
@keyframes FadeInup {
  0% {
    opacity: 0;
    -webkit-transform: translateY(30%);
    transform: translateY(30%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
.img {
  width: 60%;
  margin: 10% auto 0;
}
.simg {
  width: 100%;
  height: 100%;
}
</style>